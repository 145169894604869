/* eslint-disable camelcase */
import React from 'react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import uniqid from 'uniqid'
import * as styles from './style.module.scss'

const SingleEditionTeaser = ({ data: { date, headline, image, mailchimp_archive_link, topic_list }, slug }) => (
  <article className={styles.teaser}>
    <time className={styles.date}>{`Edition ${date.text}`}</time>
    <a href={mailchimp_archive_link.url} className={styles.link} target="_blank" rel="noreferrer">
      <img src={image.url} alt={image.alt} className={styles.image} role="presentation" />
      <h4 className={styles.title}>{headline.text}</h4>
      <ul className={styles.list}>
        {topic_list && (
          <li className={styles.listItem} key={uniqid()}>
            {parse(topic_list.html)}
          </li>
        )}
      </ul>
    </a>
  </article>
)

export default SingleEditionTeaser

SingleEditionTeaser.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
    ),
    date: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
    topic_list: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
    ),
    mailchimp_archive_link: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
  slug: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}
