// extracted by mini-css-extract-plugin
export var eyebrow = "style-module--eyebrow--33p6k";
export var ctBlack = "style-module--ctBlack--1-YPq";
export var ctSmall = "style-module--ctSmall--1ffVy";
export var ctMedium = "style-module--ctMedium--exaFj";
export var title = "style-module--title--3UTb3";
export var ctLarge = "style-module--ctLarge--_RPxH";
export var ctXLarge = "style-module--ctXLarge--W6Z2F";
export var ctIntro = "style-module--ctIntro--1c8u9";
export var ctBody = "style-module--ctBody--32sRj";
export var listItem = "style-module--listItem--2ettq";
export var ctBodyMedium = "style-module--ctBodyMedium--VGz0c";
export var ctBodyBold = "style-module--ctBodyBold--1BcgM";
export var ctLink = "style-module--ctLink--2m8Xj";
export var frutiLarge = "style-module--frutiLarge--2TEWN";
export var ctUi = "style-module--ctUi--13rhf";
export var date = "style-module--date--1uCGV";
export var ctMeta = "style-module--ctMeta--1m8_5";
export var ctMicro = "style-module--ctMicro--1ugMG";
export var ctMediumItalic = "style-module--ctMediumItalic--3iUiA";
export var teaser = "style-module--teaser--3AtZ8";
export var list = "style-module--list--2B9me";