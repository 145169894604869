/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { head } from 'lodash-es'
import { RichText } from 'prismic-reactjs'
import PropTypes from 'prop-types'
import uniqid from 'uniqid'
import CustomLink from '~utils/CustomLink'

import Seo from '~components/globals/Seo'
import Container from '~components/globals/Container'
import SingleEditionTeaser from '~components/globals/SingleEditionTeaser'
// import '~styles/app.scss'
import * as styles from './style.module.scss'

export const Archive = ({
  data: {
    allPrismicEdition: { nodes },
    prismicEditionTeaser: { data },
    allPrismicPage,
  },
  location,
}) => {
  const { sharing_description, sharing_image, sharing_title } = allPrismicPage.edges[0].node.data

  return (
    <>
      <Seo
        title={sharing_title.text}
        description={sharing_description.text}
        url={location.href}
        image={sharing_image.url}
      />
      <Container>
        <Container extraClassName={styles.pageHeader}>
          <h3 className={styles.headline} style={{ color: '#000' }}>
            {data.headline.text}
          </h3>
          <h4 className={styles.subheadline}>
            <RichText render={data.subheadline} serializeHyperlink={CustomLink} />
          </h4>
        </Container>
        <section className={styles.container}>
          {nodes.map(({ data }) => (
            <SingleEditionTeaser data={data} key={uniqid()} />
          ))}
        </section>
      </Container>
    </>
  )
}

export default Archive

export const pageQuery = graphql`
  query {
    allPrismicPage(filter: {data: {slug: {eq: "archive"}}}) {
      edges {
        node {
          data {
            sharing_description {
              text
            }
            sharing_title {
              text
            }
            sharing_image {
              url
            }
          }
        }
      }
    }
    prismicEditionTeaser {
      data {
        headline {
          text
        }
        subheadline {
          text
          html
        }
      }
    }
    allPrismicEdition(sort: {fields: data___time, order: DESC}) {
      nodes {
        id
        data {
          date {
            text
          }
          headline {
            text
          }
          topic_list {
            html
          }
          image {
            alt
            url
          }
          mailchimp_archive_link {
            url
          }
        }
      }
    }
  }
`

Archive.propTypes = {
  data: PropTypes.shape({
    allPrismicEdition: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          slugs: PropTypes.arrayOf(PropTypes.string.isRequired),
          data: PropTypes.shape({
            headline: PropTypes.arrayOf(
              PropTypes.shape({
                text: PropTypes.string.isRequired,
              }),
            ),
            date: PropTypes.string.isRequired,
            image: PropTypes.shape({
              url: PropTypes.string.isRequired,
              alt: PropTypes.string.isRequired,
            }),
            topic_list: PropTypes.arrayOf(
              PropTypes.shape({
                text: PropTypes.string.isRequired,
              }),
            ),
          }),
        }),
      ),
    }),
    prismicEditionTeaser: PropTypes.shape({
      data: PropTypes.shape({
        headline: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
          }),
        ),
        subheadline: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
          }),
        ),
      }),
    }),
    allPrismicPage: PropTypes.shape({
      data: PropTypes.shape({
        sharing_title: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
        sharing_description: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
        sharing_image: PropTypes.shape({ url: PropTypes.string }),
      }),
    }),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
}
